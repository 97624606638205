import { Directive, input } from '@angular/core';
import { SimpleValueAccessor } from '@wndr/common/core/utils/value-accessor';

/** Base input. */
@Directive()
export abstract class InputBase extends SimpleValueAccessor<string> {
	/** Input placeholder. */
	public readonly placeholder = input('');

	/** Whether input has background with opacity. */
	public readonly hasOpacity = input(false);

	/** Provides a hint to the browser about what the value of the field should be. */
	public readonly autocomplete = input<HTMLInputElement['autocomplete']>('off');
}
